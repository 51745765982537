module.exports = [{
      plugin: require('/builds/javallone/bromidic.net/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[],"color":"var(--color-red)"},
    },{
      plugin: require('/builds/javallone/bromidic.net/node_modules/gatsby-plugin-web-font-loader/gatsby-browser'),
      options: {"plugins":[],"google":{"families":["Raleway:400,800","Libre Baskerville:400,400i,700"]}},
    },{
      plugin: require('/builds/javallone/bromidic.net/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/javallone/bromidic.net/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
