// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-post-index-js": () => import("/builds/javallone/bromidic.net/src/templates/Post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-post-list-index-js": () => import("/builds/javallone/bromidic.net/src/templates/PostList/index.js" /* webpackChunkName: "component---src-templates-post-list-index-js" */),
  "component---src-pages-404-js": () => import("/builds/javallone/bromidic.net/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/javallone/bromidic.net/.cache/data.json")

